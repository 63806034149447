html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  background: linear-gradient(to top, #4f6072, #8699aa);
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload {
  position: relative;
  width: 400px;
  min-height: 445px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
  background: #fff;
  -webkit-animation: fadeup 0.5s 0.5s ease both;
          animation: fadeup 0.5s 0.5s ease both;
  transform: translateY(20px);
  opacity: 0;
}
.upload .upload-files header {
  background: #4db6ac;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
}
.upload .upload-files header p {
  color: #fff;
  font-size: 40px;
  margin: 0;
  padding: 50px 0;
}
.upload .upload-files header p i {
  transform: translateY(20px);
  opacity: 0;
  font-size: 30px;
  -webkit-animation: fadeup 0.5s 1s ease both;
          animation: fadeup 0.5s 1s ease both;
}
.upload .upload-files header p .up {
  font-weight: bold;
  transform: translateX(-20px);
  display: inline-block;
  opacity: 0;
  -webkit-animation: faderight 0.5s 1.5s ease both;
          animation: faderight 0.5s 1.5s ease both;
}
.upload .upload-files header p .load {
  display: inline-block;
  font-weight: 100;
  margin-left: -8px;
  transform: translateX(-20px);
  opacity: 0;
  -webkit-animation: faderight 1s 1.5s ease both;
          animation: faderight 1s 1.5s ease both;
}
.upload .upload-files .body {
  text-align: center;
  padding: 50px 0;
  padding-bottom: 30px;
}
.upload .upload-files .body i {
  font-size: 65px;
  color: lightgray;
}
.upload .upload-files .body b {
  font-size: 18px;
  padding-top: 15px;
  line-height: 1.4;
}
input[type="file"]::file-selector-button {
  border: 2px solid #4db6ac;
  font-size: 16px;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #4db6ac;
  transition: 1s;
  cursor: pointer;
  color: #fff;
  width: 140px;
}

input[type="file"]::-ms-browse:hover {
  background-color: #4db6ac;
  border: 2px solid #4db6ac;
}

input[type="file"]::-webkit-file-upload-button:hover {
  background-color: #4db6ac;
  border: 2px solid #4db6ac;
}

input[type="file"]::file-selector-button:hover {
  background-color: #4db6ac;
  border: 2px solid #4db6ac;
}

.upload .upload-files .body p b,
.upload .upload-files .body p a {
  color: #4db6ac;
}
.upload .upload-files .body.active {
  border: dashed 2px #4db6ac;
}
.upload .upload-files .body.active i {
  box-shadow: 0 0 0 -3px #fff, 0 0 0 lightgray, 0 0 0 -3px #fff, 0 0 0 lightgray;
  -webkit-animation: file 0.5s ease both;
          animation: file 0.5s ease both;
}
@-webkit-keyframes file {
  50% {
    box-shadow: -8px 8px 0 -3px #fff, -8px 8px 0 lightgray, -8px 8px 0 -3px #fff, -8px 8px 0 lightgray;
  }
  75%, 100% {
    box-shadow: -8px 8px 0 -3px #fff, -8px 8px 0 lightgray, -16px 16px 0 -3px #fff, -16px 16px 0 lightgray;
  }
}
@keyframes file {
  50% {
    box-shadow: -8px 8px 0 -3px #fff, -8px 8px 0 lightgray, -8px 8px 0 -3px #fff, -8px 8px 0 lightgray;
  }
  75%, 100% {
    box-shadow: -8px 8px 0 -3px #fff, -8px 8px 0 lightgray, -16px 16px 0 -3px #fff, -16px 16px 0 lightgray;
  }
}
.upload .upload-files .body.active .pointer-none > b {
  pointer-events: none;
  font-size: 18px;
}
.upload .upload-files footer {
  width: 100%;
  margin: 0 auto;
  height: 0;
}
.upload .upload-files footer .divider {
  margin: 0 auto;
  width: 0;
  border-top: solid 4px #46aba1;
  text-align: center;
  overflow: hidden;
  transition: width 0.5s ease;
}
.upload .upload-files footer .divider span {
  display: inline-block;
  transform: translateY(-25px);
  font-size: 12px;
  padding-top: 8px;
}
.upload .upload-files footer.hasFiles {
  height: auto;
}
.upload .upload-files footer.hasFiles .divider {
  width: 100%;
}
.upload .upload-files footer.hasFiles .divider span {
  transform: translateY(0);
  transition: transform 0.5s 0.5s ease;
}
.upload .upload-files footer .list-files {
  width: 320px;
  margin: 0 auto;
  margin-top: 15px;
  padding-left: 5px;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 210px;
}
.upload .upload-files footer .list-files::-webkit-scrollbar-track {
  background-color: rgba(211, 211, 211, 0.25);
}
.upload .upload-files footer .list-files::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(211, 211, 211, 0.25);
}
.upload .upload-files footer .list-files::-webkit-scrollbar-thumb {
  background-color: rgba(77, 182, 172, 0.5);
}
.upload .upload-files footer .list-files .file {
  width: 300px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  -webkit-animation: fade 0.35s ease both;
          animation: fade 0.35s ease both;
}
.upload .upload-files footer .list-files .file .name {
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80px;
  text-align: left;
}
.upload .upload-files footer .list-files .file .progress {
  width: 175px;
  height: 5px;
  border: solid 1px lightgray;
  border-radius: 2px;
  background: linear-gradient(to left, rgba(77, 182, 172, 0.2), rgba(77, 182, 172, 0.8)) no-repeat;
  background-size: 100% 100%;
}
.upload .upload-files footer .list-files .file .progress.active {
  -webkit-animation: progress 30s linear;
          animation: progress 30s linear;
}
@-webkit-keyframes progress {
  from {
    background-size: 0 100%;
  }
  to {
    background-size: 100% 100%;
  }
}
@keyframes progress {
  from {
    background-size: 0 100%;
  }
  to {
    background-size: 100% 100%;
  }
}
.upload .upload-files footer .list-files .file .done {
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: #4db6ac;
  border-radius: 50%;
  margin-left: -10px;
  transform: scale(0);
  position: relative;
}
.upload .upload-files footer .list-files .file .done:before {
  content: "View";
  position: absolute;
  top: 0;
  left: -5px;
  font-size: 24px;
  opacity: 0;
}
.upload .upload-files footer .list-files .file .done:hover:before {
  transition: all 0.25s ease;
  top: -30px;
  opacity: 1;
}
.upload .upload-files footer .list-files .file .done.anim {
  -webkit-animation: done1 0.5s ease forwards;
          animation: done1 0.5s ease forwards;
}
.upload .upload-files footer .list-files .file .done.anim #path {
  -webkit-animation: done2 2.5s 0.5s ease forwards;
          animation: done2 2.5s 0.5s ease forwards;
}
.upload .upload-files footer .list-files .file .done #path {
  stroke-dashoffset: 7387.5942382813;
  stroke-dasharray: 7387.5942382813 7387.5942382813;
  stroke: #fff;
  fill: transparent;
  stroke-width: 50px;
}
@-webkit-keyframes done2 {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes done2 {
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes done1 {
  50% {
    transform: scale(0.5);
    opacity: 1;
  }
  80% {
    transform: scale(0.25);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 1;
  }
}
@keyframes done1 {
  50% {
    transform: scale(0.5);
    opacity: 1;
  }
  80% {
    transform: scale(0.25);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 1;
  }
}
.footerLinks{display:flex; justify-content: center;}
.footerLinks a{ text-decoration: none; color:#2f8f27; text-transform: uppercase ;}
.importar {
  width: 125px;
  outline: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: auto;
  border: solid 1px #4db6ac;
  color: #fff;
  background: #4db6ac;
  padding: 10px 5px;
  font-size: 20px;
  border-radius: 4px;
  font-family: Roboto;
  line-height: 1;
  cursor: pointer;
}
.upload .upload-files footer .importar.active {
  transition: transform 0.5s 1.5s ease, opacity 0.5s 1.5s ease, background;
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.upload .upload-files footer .importar:hover {
  background: #4db6ac;
  color: #fff;
}
@-webkit-keyframes fadeup {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeup {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes faderight {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes faderight {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  to {
    opacity: 1;
  }
}
@keyframes fade {
  to {
    opacity: 1;
  }
}
@media (max-width: 400px) {
  .upload {
    width: 100%;
    height: 100%;
  }
}